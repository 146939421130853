import React from "react"
import styled, { css } from "styled-components"
import rocket from "../images/rocket-color.png"
import RainbowTrail from "./RainbowTrail"

const getInt = () => Math.floor(Math.random() * 100)
const drawStars = number => {
  let bgImg = "background-image: "
  let bgPos = "background-position:"
  for (let i = 0; i <= number; i += 1) {
    bgImg += `radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),`
    bgPos += `${getInt()}% ${getInt()}%,`
  }
  return css`
    ${bgImg.slice(0, -1)};
    ${bgPos.slice(0, -1)};
  `
}

const SBanner = styled.section`
  height: 100vh;
  background: linear-gradient(
    90deg,
    black,
    #020a13,
    #17091a,
    #120619,
    #060b15,
    black
  );
  background-size: 800%;
  background-position: 0;
  animation: clouds 120s linear infinite;
  position: relative;
  overflow: hidden;
  user-select: none;
  cursor: none;
  .rocket {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 50%;
    max-height: 50%;
    display: block;
    margin: auto;
    animation: fadeScale 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  }
  .innerBG {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    ${drawStars(20)}
    background-size: 4px 4px;
    background-repeat: no-repeat;
    opacity: 0.4;
  }
  .mouse-canvas {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  @keyframes clouds {
    from {
      background-position: 0;
    }
    to {
      background-position: 800%;
    }
  }
  @keyframes glow {
    from {
      opacity: 0.8;
    }
    to {
      opacity: 1;
      transform: scale(1.02, 1.02);
    }
  }
  @keyframes fadeScale {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`

const HomeBanner = () => {
  return (
    <RainbowTrail>
      <SBanner id="home" className="home-banner rainbow-mouse-container">
        <div className="innerBG"></div>
        <img className="rocket" src={rocket} alt="Rocket" />
      </SBanner>
    </RainbowTrail>
  )
}

export default HomeBanner
