import React from "react";
import styled from "styled-components";

const SRainbowTrail = styled.div`
  position: relative;
  .loader-container {
    width: 100px;
    height: 100px;
    position: absolute;
    animation: scaleUp 0.5s linear;
    transform: scale(0);
    opacity: 0;
    .loader {
      background: #ff0c0c;
      background: linear-gradient(50deg, #ff0c0c, #21d400);
      border-radius: 5px;
      filter: hue-rotate(0deg);
      animation: filterHue 2.5s linear infinite;
      height: 100%;
      width: 100%;
      transform: rotate(0deg);
      &:after {
        content: "";
        position: absolute;
        height: 80px;
        width: 80px;
        left: 10px;
        top: 10px;
        border-radius: 100%;
      }
    }
  }

  @keyframes scaleUp {
    50% {
      transform: scale(0.5);
      opacity: 1;
    }
    100% {
      transform: scale(0.25);
    }
  }
  @keyframes filterHue2 {
    50% {
      filter: hue-rotate(1000deg);
    }
    100% {
      filter: hue-rotate(2000deg);
    }
  }

  @keyframes filterHue {
    50% {
      filter: hue-rotate(1000deg);
    }
    100% {
      filter: hue-rotate(2000deg);
      transform: rotate(360deg);
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .loader {
      background: linear-gradient(50deg, rgba(240, 0, 80, 1), #2380ff)
        important!;
    }
  }
`;

const RainbowTrail = ({ children }) => {
  const drawTrail = (e) => {
    var to_append = document.getElementsByClassName("loader-container")[0];
    var parent_div = document.createElement("div");
    parent_div.className = "loader-container";
    var inner_div = document.createElement("div");
    inner_div.className = "loader";
    parent_div.appendChild(inner_div);
    document.getElementsByClassName("home-banner")[0].appendChild(parent_div);
    parent_div.style.left = e.clientX - 50 + "px";
    parent_div.style.top = e.clientY - 50 + "px";
    if (document.getElementsByClassName("loader-container").length > 50) {
      document.getElementsByClassName("home-banner")[0].removeChild(to_append);
    }
  };
  return (
    <SRainbowTrail onMouseMove={drawTrail}>
      {children}
      <div className="trail-container"></div>
    </SRainbowTrail>
  );
};

export default RainbowTrail;
