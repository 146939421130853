import * as React from "react"
import VideoBlock from "../components/VideoBlock"
import HomeBanner from "../components/HomeBanner"
import Layout from "../components/Layout"
import { email } from "../content/content"
import Cards from "../components/Cards"
import Section from "../components/Section"
import { Button, CenteredBox, FadeUp, StaticCenteredBox } from "../styles/ui"
import TrackVisibility from "react-on-screen"
import rocketWireframe from "../images/rocket_wireframe.png"
import theme from "../styles/theme"
import { darken } from "polished"
import { BiMovie } from "react-icons/bi"
import { VscRocket, VscTools } from "react-icons/vsc"
import { RiMailSendLine } from "react-icons/ri"
import HalfBlock from "../components/HalfBlock"
import Icon3D from "../images/icons/icon_3d.png"
import IconEvent from "../images/icons/icon_events.png"
import Iconwebsite from "../images/icons/icon_webdesign.png"
import IconStudio from "../images/icons/icon_studio2.png"
import IconCommunity from "../images/icons/icon_community.png"
import SEOpt from "../components/SEOpt"
import { FaHeadphones } from "react-icons/fa"

const aboutBlocks = [
  {
    title: "Media production",
    text:
      "Rocketship media crew conceives, develops, directs and produces a variety of media, from promotional videos to music videos, live streams and animation",
    img: {
      src: IconStudio,
      altText: "Camera icon",
    },
    button: {
      text: "Let's produce",
      icon: <BiMovie />,
      url: `mailto:${email}?subject=Let's make a video`,
    },
  },
  {
    title: "Community",
    text:
      "Rocketshipper family at the heart of our organization is a vibrant 1000+ member community active over multiple social networks, groups and chats",
    img: {
      src: IconCommunity,
      altText: "Group icon",
    },
    button: {
      text: "Come on board",
      icon: <VscRocket />,
      url: "https://www.facebook.com/groups/182658192981713/",
    },
  },
  {
    title: "Events",
    text:
      "We love to organize bright exciting events to have fun and bring people together",
    img: {
      src: IconEvent,
      altText: "Speakers icon",
    },
    button: {
      text: "Check out our events",
      icon: <FaHeadphones />,
      to: "events",
    },
  },
  {
    title: "Small-scale manufacturing",
    text:
      "Using cool modern software, tools and machines we design, print, cut and engrave all kinds of items to work and play with",
    img: {
      src: Icon3D,
      altText: "3D-printer icons",
    },
    button: {
      text: "Make something",
      icon: <VscRocket />,
      url: `mailto:${email}?subject=I want to make a thing`,
    },
  },
  {
    title: "Projects",
    text:
      "We create, produce, build, film, organize, paint, craft, sew and occasionally destroy things for fun",
    img: {
      src: Iconwebsite,
      altText: "Companion cube icon",
    },
    button: {
      text: "Let's collaborate",
      icon: <VscTools />,
      url: `mailto:${email}?subject=Let's collaborate on a project`,
    },
  },
]

// markup
const IndexPage = () => {
  return (
    <Layout>
      <SEOpt title="Home base" />
      <HomeBanner />
      <Section>
        <CenteredBox>
          <div className="section-head-wrap">
            <TrackVisibility>
              <FadeUp>
                <h1>The Rocketship</h1>
              </FadeUp>
            </TrackVisibility>
            <TrackVisibility>
              <FadeUp>
                <h2>Who we are &amp; what we do</h2>
              </FadeUp>
            </TrackVisibility>
            <TrackVisibility>
              <FadeUp>
                <p className="sub-head">
                  The Rocketship is a multimedia company behind an
                  Amsterdam-based artist collective
                </p>
              </FadeUp>
            </TrackVisibility>
          </div>
        </CenteredBox>
        <StaticCenteredBox>
          <Cards items={aboutBlocks} />
        </StaticCenteredBox>
      </Section>
      <VideoBlock
        title="Virtual production"
        subtitle="Virtual production blends video game technology with filmmaking
              techniques into the pre-production and production process"
        videoTitle="Virtual production with Unreal engine"
        videoUrl="https://www.youtube.com/embed/u2Vo1BYGXHs"
      />
      <HalfBlock
        background={darken(0.02, theme.bg)}
        imgSrc={rocketWireframe}
        title="Help us fly"
        titleLarger="the Rocketship!"
        text="We're constantly looking for artists, creators and
        volunteers to help us fly the Rocketship. If you're
        interested in working with us send us a message."
        cta={
          <Button as="a" href={`mailto:${email}?subject=Let's collaborate!`}>
            Get in touch <RiMailSendLine />
          </Button>
        }
      />
    </Layout>
  )
}

export default IndexPage
